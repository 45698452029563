<template>
    <Modal v-model="isShowAlertModal" title="親愛的夥伴您好" width="500px">
        <template v-slot:modal-content>
            <div class="alert-content">
                {{ reminderModal.alertContent }}
            </div>
            <div class="icon alert-image">
                <img
                    v-if="reminderModal.img"
                    :src="require(`../../assets/images/${reminderModal.img}`)"
                    alt="系統畫面"
                />
            </div>
        </template>
        <template v-slot:modal-bottom>
            <a
                href="https://eip.leishan.com.tw:8080/Default.aspx?web=/"
                target="_blank"
                @click="loginPKS($event)"
            >
                <Button buttonStyle="dashboard" buttonWord="點我前往 PKS" />
            </a>
        </template>
    </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue'
import Button from '@/components/Button.vue'
import { getPKSTokenAPI } from '@/assets/javascripts/api'

export default {
    name: 'ReminderModal',
    props: {
        modelValue: {
            type: Boolean,
            default: function () {
                return false
            }
        },
        reminderModal: {
            type: Object,
            default: function () {
                return {
                    alertContent: '',
                    img: ''
                }
            }
        }
    },
    components: { Modal, Button },
    computed: {
        isShowAlertModal: {
            get() {
                return this.modelValue
            },
            set(val) {
                this.$emit('update:modelValue', val)
            }
        }
    },
    methods: {
        /**
         * 自動登入PKS
         * @param $event click event
         */
        loginPKS: async function ($event) {
            $event.preventDefault()
            let hrefPrefix = 'https://eip.leishan.com.tw:8080/'
            const defaultHref = `${hrefPrefix}/Default.aspx?web=/`
            const newPage = window.open('', '_blank')
            newPage.document.write(` <div>登入中請稍候...</div>`)
            try {
                const id = this.$store.state.userInfo.account
                const { data } = await getPKSTokenAPI(id)
                if (newPage && data) {
                    newPage.location.href = `${hrefPrefix}/LSLogin.aspx?token=${data.token}`
                } else {
                    newPage.location.href = defaultHref
                }
            } catch (error) {
                newPage.location.href = defaultHref
            }
        }
    }
}
</script>
